@import "src/app/scss/mixins/functions";
@import "src/app/scss/variables";

.wrap {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  :global {
    .fas {
      font-size: rem-calc(50);
      color: $blue;
    }
  }
}
