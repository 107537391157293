.dspl-section {
  padding: rem-calc(80) 0 rem-calc(100);
  color: $gray;

  @include respond-below(sm) {
    padding: rem-calc(100) 0;
  }

  @include respond-below(xs) {
    padding: rem-calc(50) 0;
  }

  &__content-center {
    margin: 0 auto;
    width: rem-calc(1160);
    max-width: 100%;
    text-align: center;
    letter-spacing: 0;
  }

  &__content-img {
    display: flex;

    @include respond-below(xl) {
      display: block;
    }

    &_left-block {
      width: 100%;

      img {
        width: 100%;
      }
    }

    &_right-block {
      background: $sky-gray;
      width: 100%;
      display: flex;
      align-items: center;
    }

    &_text-content {
      padding: 0 rem-calc(60);
      width: 100%;
      max-width: rem-calc(800);

      @include respond-below(xxl) {
        padding: 0 rem-calc(30);
      }

      @include respond-below(xl) {
        max-width: 100%;
        padding: rem-calc(60);
      }

      @include respond-below(md) {
        max-width: 100%;
        padding: rem-calc(50);
      }
    }
  }

  h1 {
    font-size: rem-calc(52);
    line-height: rem-calc(71);
    font-weight: 700;
    padding: 0;
    margin: 0 0 rem-calc(34) 0;

    @include respond-below(md) {
      font-size: rem-calc(42);
      line-height: rem-calc(51);
      margin-bottom: 1rem;
    }
  }

  h2 {
    font-size: rem-calc(34);
    line-height: rem-calc(46);
    padding: 0;
    margin: 0;

    @include respond-below(md) {
      text-align: center;
      margin-bottom: 2rem;
    }
  }

  p {
    font-size: rem-calc(20);
    line-height: rem-calc(30);
    margin: 0 0 rem-calc(18) 0;

    @include respond-below(md) {
      font-size: rem-calc(23);
      line-height: rem-calc(35);
    }
  }

  .btn {
    @include respond-below(md) {
      font-size: rem-calc(25);
    }
  }

  &_tms-dispatchland-img {
    padding: 0;
  }

  &_tms {
    padding: rem-calc(160) 0 rem-calc(100);

    @include respond-below(md) {
      padding: rem-calc(120) 0;
    }

    @include respond-below(xs) {
      padding: rem-calc(50) 0;
    }
  }

  &_tms-advantages {
    padding: rem-calc(150) 0 rem-calc(105);
    background: url("../../static/img/advantages_section_bg.jpg") 0 0 no-repeat;
    background-size: cover;
    color: $white;

    h2 {
      @include respond-below(xs) {
        font-size: rem-calc(30);
        text-align: left;
      }
    }

    @include respond-below(md) {
      padding: rem-calc(90) 0;
    }

    @include respond-below(xs) {
      padding: rem-calc(50) 0;
    }
  }

  &_tms-advantages-do {
    padding: rem-calc(130) 0 rem-calc(105);
    margin: 0 auto;
    width: rem-calc(1400);
    max-width: 100%;

    @include respond-below(md) {
      padding: rem-calc(90) 0;
    }

    @include respond-below(xs) {
      padding: rem-calc(60) 0;
    }

    h2 {
      text-align: center;
      max-width: rem-calc(1240);
      margin: 0 auto rem-calc(80);

      @include respond-below(md) {
        margin-bottom: 2rem;
      }
    }
  }

  &_tms-dispatchland {
    color: $white;
    background: $persian-blue;
  }

  &_tms-loadBoard {
    background: $blue-elis;

    .loadBoard_row {
      display: flex;
      align-items: center;
      &__img{
        width: 100%;
      }
      &__buttons-transportation {
        display: flex;
        //justify-content: space-around;

        @include respond-below(md) {
          justify-content: center;

          flex-direction: column;
          align-items: center;
        }
        :nth-child(2) {
          @include respond-below(md) {
            margin-top: 10px;
            margin-left: 0;
          }
          margin-left: 11px;
        }

      }
      &__flag-transportation {
        display: inline-block !important;
        margin-right: 8px;
        width: 25px;
      }
    }
    a{

      width: 255px;
    }
    p {
      margin-top: 2rem;
    }


  }

  &_access {
    padding: rem-calc(80) 0 rem-calc(80);
    background: url("../../static/img/access_section_bg.jpg") 0 0 no-repeat;
    background-size: cover;
    color: $white;

    @include respond-below(xl) {
      background: url("../../static/img/access_section_bg_tablet.jpg") right 0 no-repeat;
      background-size: cover;
    }

    @include respond-below(md) {
      padding: rem-calc(50) 0;
      background: url("../../static/img/access_section_bg_tablet.jpg") 0 0 no-repeat;
      background-size: cover;
      text-align: center;
    }

    h2 {
      font-weight: 700;
      letter-spacing: 0;
      margin-bottom: rem-calc(34);
    }

    p {
      margin-bottom: rem-calc(34);
    }
  }

  &_solutions {
    h1 {
      text-align: center;
      margin-bottom: rem-calc(100);

      @include respond-below(md) {
        margin-bottom: 1rem;
      }
    }

    img {
      width: 100%;
    }

    .solutions-row {
      margin-bottom: rem-calc(150);
      align-items: center;

      @include respond-below(md) {
        margin-bottom: rem-calc(40);
      }

      &:last-child {
        margin: 0;

        @include respond-below(md) {
          margin-right: rem-calc(-15);
          margin-left: rem-calc(-15);
          margin-bottom: rem-calc(40);
        }
      }

      h2 {
        font-weight: 600;
        letter-spacing: 0;
        margin-bottom: rem-calc(20);

        @include respond-below(md) {
          text-align: left;
        }
      }

      img {
        @include respond-below(md) {
          margin-bottom: rem-calc(40);
        }
      }

      &__img {
        @include respond-below(md) {
          width: calc(100% + #{rem-calc(30, 12)});
          margin-left: rem-calc(-15, 12);
        }
      }

      &__actions {
        @include respond-below(md) {
          display: flex;
        }

        img {
          width: auto;
          margin-right: rem-calc(20);

          @include respond-below(xl) {
            margin-right: rem-calc(10);
          }

          @include respond-below(md) {
            margin-right: rem-calc(10);
            height: rem-calc(70);
          }

          @include respond-below(sm) {
            margin-right: rem-calc(10);
            height: rem-calc(60);
          }
        }

        > a:last-child {
          img {
            margin-right: 0;
          }
        }
      }

      &__content {
        margin-left: rem-calc(40);

        @include respond-below(md) {
          margin: 0;
        }
      }

      &_right {
        margin-left: 0;
        margin-right: rem-calc(40);

        @include respond-below(md) {
          margin: 0;
        }
      }

      &_reverse {
        @include respond-below(md) {
          flex-direction: column-reverse;
        }
      }
    }
  }

  &_partners {
    h1 {
      text-align: center;
      margin-bottom: rem-calc(80);

      @include respond-below(md) {
        margin-bottom: rem-calc(20);
      }
    }
  }
}
