.dspl-main {
  &__support {
    position: fixed;
    bottom: rem-calc(80);
    right: rem-calc(20);
    width: rem-calc(61);
    height: rem-calc(61);
    background: url('../../static/icon/technical-support.svg') center center no-repeat;
    transition: all 0.3s ease-in-out;
    border-radius: 50%;
    z-index: 101;

    @include respond-below(md) {
      z-index: 1000;
      width: rem-calc(75);
      height: rem-calc(75);
    }

    &:hover {
      background: url('../../static/icon/technical-support__hover.svg') center center no-repeat $orange;
    }

    &_bottom {
      bottom: rem-calc(40);
    }
  }

  &__btn-top {
    position: fixed;
    bottom: rem-calc(20);
    right: rem-calc(30);
    width: rem-calc(40);
    height: rem-calc(40);
    background: $creamy-gray;
    border-radius: 50%;
    opacity: 0;
    transition: all 0.3s ease-in-out;
    padding: 0;
    text-align: center;
    z-index: 101;

    @include respond-below(md) {
      z-index: 1000;
      width: rem-calc(50);
      height: rem-calc(50);
    }

    i {
      color: $gray;
      font-size: rem-calc(34);
      line-height: rem-calc(41);
    }

    &:hover {
      background: $light-gray;
    }

    &_visible {
      opacity: 1;
    }
  }
}
