.dspl-footer {
  background: $dark-blue;
  color: $white;
  padding: rem-calc(40) 0 rem-calc(20);
  font-size: rem-calc(16);
  line-height: rem-calc(22);

  address {
    margin: 0;
    font-size: rem-calc(16);
  }

  .container {
    .row:nth-child(1) {
      >div {
        &:nth-child(1),
        &:nth-child(4) {
          @include respond-below(xs) {
            text-align: center;
          }
        }
      }
    }
  }

  a,
  .btn-link {
    display: inline-block;
    color: $white;
    transition: all 0.3s ease-in-out;
    padding: 0 0 rem-calc(1) 0;
    border-bottom: rem-calc(1) solid transparent;
    margin-bottom: rem-calc(8);
    border-radius: 0;
    text-align: left;

    .fas {
      transition: all 0.3s ease-in-out;
      font-size: rem-calc(14);
      margin-right: rem-calc(5);
    }

    &:hover {
      text-decoration: none;
      border-bottom: 1px solid $white;

      .fas {
        color: $blue;
      }
    }

    &.nav-link {
      padding: 0 0 rem-calc(1) 0;
    }
  }

  h5 {
    font-size: 1rem;
    margin-bottom: 1rem;
    letter-spacing: 0;
    color: $blue;

    @include respond-below(xl) {
      margin: 1rem 0 1.5rem;
    }

    @include respond-below(sm) {
      margin: 1rem 0 1.5rem;
      font-size: 1.2rem;
    }

    @include respond-below(xs) {
      margin: 0.7rem 0 1.3rem;
      font-size: 1.1rem;
    }
  }

  &__copy {
    margin-top: rem-calc(20);
    padding-top: rem-calc(20);
    border-top: rem-calc(1) solid $light-gray;
  }

  &__logo {
    margin-bottom: rem-calc(10);

    @include respond-below(xs) {
      height: rem-calc(30);
    }
  }

  &__mobile {
    .mobile-link {
      margin-right: rem-calc(20);

      &:hover {
        border-bottom: rem-calc(1) solid transparent;
      }
    }
  }

  &__social {
    margin-left: 0;
    padding-left: 0;
    display: flex;

    @include respond-below(xs) {
      justify-content: center;
    }

    li {
      margin-right: 1rem;
      list-style-type: none;

      .social-link {
        cursor: pointer;

        &:focus {
          box-shadow: none;
        }

        > * {
          display: inline-block;
          width: rem-calc(30);
          height: rem-calc(30);
          transition: all 0.3s ease-in-out;

          @include respond-below(md) {
            width: rem-calc(40);
            height: rem-calc(40);
          }
        }

        .facebook {
          background: url('../../static/icon/facebook_white.svg') 0 0 no-repeat;
        }

        .linkedin {
          background: url('../../static/icon/linkedin_white.svg') 0 0 no-repeat;
        }

        .insta {
          background: url('../../static/icon/instagram_white.svg') 0 0 no-repeat;
        }

        .youtube {
          width: rem-calc(41);
          height: rem-calc(30);
          background: url('../../static/icon/youtube_white.svg') 0 0 no-repeat;

          @include respond-below(md) {
            width: rem-calc(53);
            height: rem-calc(40);
          }
        }

        &:hover {
          border-color: transparent;

          .facebook {
            background: url('../../static/icon/facebook.svg') 0 0 no-repeat;
          }

          .linkedin {
            background: url('../../static/icon/linkedin.svg') 0 0 no-repeat;
          }

          .insta {
            background: url('../../static/icon/instagram.svg') 0 0 no-repeat;
          }

          .youtube {
            background: url('../../static/icon/youtube.svg') 0 0 no-repeat;
          }
        }
      }
    }
  }
}
