// Custom.scss
// Option B: Include parts of Bootstrap

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1440px,
  xxl: 1660px
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1400px,
  xxl: 1600px
);

$navbar-nav-link-padding-x: rem-calc(30);

$blue: $blue;
$orange: $orange;
$red: $red;
$green: $green;

$navbar-padding-y: 0;
$navbar-padding-x: 0;
$navbar-light-color: $gray;
$navbar-light-hover-color: rgba($navbar-light-color, 0.7);

$btn-padding-y-lg: rem-calc(15);
$btn-padding-x-lg: rem-calc(20);
$btn-font-size-lg: rem-calc(20);
$btn-line-height-lg: rem-calc(27);
$btn-border-radius-lg: rem-calc(10);

$modal-content-border-radius: rem-calc(20);
$modal-content-border-width: 0;

$modal-content-box-shadow-xs: 0 rem-calc(1) rem-calc(4) $dark-gray;

$input-color: $dark-blue;
$input-height: rem-calc(44);
$input-border-color: $blue;
$input-border-width: 2px;

$input-border-radius: 5px;

$input-focus-border-color: $sapphire-blue;

$input-placeholder-color: $silver-gray;
$input-btn-focus-color: rgba($sapphire-blue, 0.25) !default;

.btn-lg {
  min-width: rem-calc(200);

  &.btn-primary {
    &:hover,
    &:focus,
    &:active {
      background: $royal-blue;
      border-color: $royal-blue;
    }
  }

  &.btn-outline-white {
    color: $white;
    border: 1px solid $white;

    &:hover,
    &:focus,
    &:active {
      color: $gray;
      background: $white;
      border-color: $white;
    }
  }
}

.container,
.navbar {
  height: 100%;
}

// Required

@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";
@import "node_modules/bootstrap/scss/utilities";

// Optional

@import "node_modules/bootstrap/scss/reboot";
@import "node_modules/bootstrap/scss/buttons";
@import "node_modules/bootstrap/scss/nav";
@import "node_modules/bootstrap/scss/navbar";
@import "node_modules/bootstrap/scss/close";
@import "node_modules/bootstrap/scss/dropdown";
@import "node_modules/bootstrap/scss/media";
@import "node_modules/bootstrap/scss/type";
@import "node_modules/bootstrap/scss/images";
@import "node_modules/bootstrap/scss/code";
@import "node_modules/bootstrap/scss/grid";
@import "node_modules/bootstrap/scss/transitions";
@import "node_modules/bootstrap/scss/modal";

.modal-footer {
  justify-content: center;
  padding-bottom: rem-calc(50);
}

.modal-body {
  padding: 1rem rem-calc(50);
}

.btn-warning {
  color: $white;
  border: 1px solid $light-orange;
  background: $light-orange;

  &:hover,
  &:focus,
  &:active {
    color: $white;
    background: $orange;
    border-color: $orange;
  }
}

.btn-warning:not(:disabled):not(.disabled):active,
.btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  color: $white;
  background: $orange;
  border-color: $orange;
}

.btn-error {
  color: $white;
  border: 1px solid $light-red;
  background: $light-red;

  &:hover,
  &:focus,
  &:active {
    color: $white;
    background: $red;
    border-color: $red;
  }
}

.btn-success {
  color: $white;
  border: 1px solid $jade-green;
  background: $jade-green;

  &:hover,
  &:focus,
  &:active {
    color: $white;
    background: $green;
    border-color: $green;
  }
}

.nav-link {
  cursor: pointer;
}

@import "node_modules/bootstrap/scss/forms";
