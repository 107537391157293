.dspl-info-modal {
  max-width: rem-calc(500);
  font-family: $font-open-sans;

  @include respond-below(md) {
    font-size: rem-calc(16, 12);
    text-align: center;
  }

  .modal-content {
    padding: rem-calc(20);
  }

  &__header {
    position: relative;
    padding: rem-calc(10) 0 rem-calc(20);
  }

  &__title {
    font-size: rem-calc(20);
    font-weight: 600;
    line-height: rem-calc(27);
    letter-spacing: 0;

    @include respond-below(md) {
      font-size: rem-calc(20, 12);
      line-height: rem-calc(27, 12);
      text-align: center;
    }

    &_success {
      color: $green;
    }

    &_error {
      color: $red;
    }
  }

  &__footer {
    text-align: right;
    padding-top: rem-calc(30);
  }

  &__btn {
    display: inline-block;
    font-size: rem-calc(20);
    line-height: rem-calc(27);
    letter-spacing: 0;
    min-width: rem-calc(160);
    height: rem-calc(40);
    border-radius: rem-calc(10);
    text-align: center;
    padding: 0 rem-calc(10);
    margin-left: rem-calc(10);

    @include respond-below(md) {
      display: block;
      margin: 0 auto;
      font-size: rem-calc(20, 12);
      line-height: rem-calc(27, 12);
      height: rem-calc(40, 12);
      min-width: rem-calc(160, 12);
    }
  }
}
