html {
  @include respond-below(md) {
    font-size: rem-calc(12);
  }
}

.dspl-landing-page {
  position: relative;
  min-height: 100vh;
  font-family: $font-open-sans;
  font-size: rem-calc(20);
  line-height: rem-calc(27);

  &.no-scroll {
    overflow: hidden;
    max-height: calc(100vh - #{rem-calc(200)});
  }
}

.spinner-border {
  position: absolute;
  top: calc(50% - 1rem);
  left: calc(50% - 1rem);
}

#partners-section .row {
  justify-content: center;
}
