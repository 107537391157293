.dspl-form {
  padding: rem-calc(0) rem-calc(50) rem-calc(50);

  h2 {
    font-size: rem-calc(34);
    line-height: rem-calc(46);
    letter-spacing: 0;
    color: $dark-blue;
    padding: 0;
    margin: 0 0 rem-calc(20) 0;
  }

  &__label {
    font-size: rem-calc(20);
    line-height: rem-calc(27);
    letter-spacing: 0;
    color: $dark-blue;

    &_required {
      font-size: rem-calc(20);
      color: $light-red;
    }
  }

  &__select {
    > option[value=""] {
      visibility: hidden;
      opacity: 0;
      height: 0;
    }

    &:not(.is-invalid) {
      background-image:
        linear-gradient(45deg, transparent 50%, $silver-gray 60%),
        linear-gradient(135deg, $silver-gray 40%, transparent 50%);
      background-position:
        calc(100% - 17px) 16px,
        calc(100% - 10px) 16px,
        100% 0;
      background-size:
        rem-calc(7) rem-calc(7),
        rem-calc(7) rem-calc(7);
      background-repeat: no-repeat;
      -webkit-appearance: none;
      -moz-appearance: none;
    }

    &.is-invalid {
      -webkit-appearance: none;
      -moz-appearance: none;
    }

    &.placeholder {
      color: $silver-gray;

      &:focus,
      &:active {
        color: $silver-gray;

        > option {
          color: $dark-blue;

          &:first-child {
            visibility: hidden;
            opacity: 0;
            height: 0;
          }
        }
      }
    }
  }

  &__section {
    margin-bottom: rem-calc(20);
  }

  &__footer {
    text-align: center;

    .btn {
      @include respond-below(md) {
        font-size: rem-calc(18, 12);
        line-height: rem-calc(27, 12);
        height: rem-calc(50, 12);
        min-width: rem-calc(160, 12);
      }
    }
  }
}
