.dspl-modal {
  max-width: rem-calc(1040);
  font-family: $font-open-sans;

  &__header {
    padding: rem-calc(30);
    position: relative;
  }

  &__title {
    text-align: center;
    padding-top: rem-calc(20);
    font-size: rem-calc(52);
    line-height: rem-calc(71);
    letter-spacing: 0;
    color: $blue;

    &_orange {
      color: $orange;
    }
  }

  &__close {
    font-size: rem-calc(26);
    line-height: 1;
    color: $dark-blue;
    padding: rem-calc(5);
    position: absolute;
    right: rem-calc(30);
    top: rem-calc(30);

    &:hover {
      color: $red;
    }
  }
}
