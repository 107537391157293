.dspl-empty {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  &__content {
    color: rgba(0, 0, 0, 0.25);
    font-size: rem-calc(14);
    line-height: 1.5715;
    text-align: center;
  }
}
