.dspl-header {
  padding: 0 rem-calc(20);
  height: rem-calc(64);
  box-shadow: 0 rem-calc(1) rem-calc(4) $dark-gray;

  @include respond-below(lg) {
    background: $cobalt-blue;
    height: auto;
  }

  &__btn {
    display: inline-block;
    font-size: rem-calc(20);
    line-height: rem-calc(27);
    letter-spacing: 0;
    min-width: rem-calc(160);
    height: rem-calc(40);
    border-radius: rem-calc(10);
    text-align: center;
    padding: 0 rem-calc(10);
    margin-left: rem-calc(10);

    &_outline {
      border: 1px solid $gray;
      color: $gray;
      line-height: rem-calc(37);
      text-decoration: none;
      transition: all 0.15s ease-in-out;

      &:hover {
        text-decoration: none;
        border: 1px solid $royal-blue;
        color: $royal-blue;
      }
    }
  }
}
