//fonts
$font-open-sans: 'Open Sans', sans-serif;

//colors

$white: #fff;
$black: #000;
$dark-gray: #00000029;
$gray: #333;
$silver-gray: #828282;
$french-gray: #bdbdbd;
$persian-gray: #fcfcfc;
$sky-gray: #e5f5fd;
$light-gray: #e0e0e0;
$creamy-gray: #f2f2f2;
$dark-blue: #22354e;
$cobalt-blue: #163156;
$persian-blue: #003f84;
$sapphire-blue: #2f70b8;
$royal-blue: #3581d5;
$blue: #039be6;
$light-blue: #c1e6f9;
$red: #e93c3c;
$light-red: #fb4e4e;
$green: #06a350;
$jade-green: #27af6d;
$orange: #ff8c00;
$light-orange: #fda53b;
$blue-elis: #E5F5FD
